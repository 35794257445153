import { Component, OnInit, HostListener } from '@angular/core';
import {
  style,
  transition,
  animate,
  trigger
} from '@angular/animations';
import { Router } from '@angular/router';

@Component({
  selector: 'vmm-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  animations: [
    trigger('smallNavAnimation', [
      transition(':enter', [
        style({
          opacity: 0,
          transform: 'translateX(-100%)'
        }),
        animate(
          '400ms ease-in-out',
          style({
            opacity: 1,
            transform: 'translateX(0)'
          })
        )
      ]),
      transition(
        ':leave',
        animate(
          '400ms ease-in-out',
          style({
            opacity: 0,
            transform: 'translateX(-100%)'
          })
        )
      )
    ])
  ]
})
export class NavigationComponent implements OnInit {
  showSmallNav: boolean;
  constructor(private router: Router) { }
  ngOnInit() { }

  @HostListener('window:scroll')
  checkScroll() {
    if (window && window.pageYOffset >= 150) {
      this.showSmallNav = true;
    } else {
      this.showSmallNav = false;
    }
  }

  toHome(): void {
    this.router.navigate(['']);
    this.toTop();
  }

  toTop(): void {
    if (window) {
      window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
    }
  }
}
