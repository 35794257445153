<nav id="main-nav">
  <mat-toolbar class="mat-elevation-z2">
    <a class="logo mat-button" routerLink="/" matTooltip="Startseite" matTooltipPosition="right">
      <img vmm-svg name="logo" alt="Vmm GmbH">
      <span>VMM GmbH</span>
    </a>
    <span class="spacer"></span>
    <a class="mat-button" routerLink="/kontakt">Kontakt</a>
    <a mat-flat-button matTooltip="Senden Sie uns eine kostenlose und unverbindliche Anfrage" matTooltipPosition="left" routerLink="/anfrage"
      color="primary">Angebotsanfrage</a>
  </mat-toolbar>
</nav>
<nav id="small-nav" [@smallNavAnimation]="showSmallNav" aria-hidden="true" *ngIf="showSmallNav">
  <div matTooltip="Startseite" matTooltipPosition="right" class="mat-app-background mat-elevation-z2">
    <button mat-icon-button aria-label="Zur Startseite" (click)="toHome()" tabindex="-1">
      <mat-icon>home</mat-icon>
    </button>
  </div>
  <div matTooltip="Nach oben" matTooltipPosition="right" class="mat-app-background mat-elevation-z2">
    <button mat-icon-button aria-label="Nach oben scrollen" (click)="toTop()" tabindex="-1">
      <mat-icon>arrow_upward</mat-icon>
    </button>
  </div>
</nav>
