import { Component, OnInit, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'img[vmm-svg]',
  templateUrl: './svg.component.html',
  styleUrls: ['./svg.component.scss']
})
export class SvgComponent implements OnInit {
  @HostBinding('attr.src')
  src: string;

  @Input()
  name: string;

  ngOnInit() {
    this.src = '/assets/' + this.name + '.svg';
  }
}
