import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'kontakt',
    loadChildren: () =>
      import('./contact/contact.module').then((m) => m.ContactModule),
  },
  {
    path: 'datenschutz',
    loadChildren: () => import('./data/data.module').then((m) => m.DataModule),
  },
  {
    path: 'anfrage',
    loadChildren: () =>
      import('./request/request.module').then((m) => m.RequestModule),
  },
  {
    path: 'wir',
    loadChildren: () =>
      import('./about/about.module').then((m) => m.AboutModule),
  },
  {
    path: 'agb',
    loadChildren: () =>
      import('./terms/terms.module').then((m) => m.TermsModule),
  },
  {
    path: 'avb',
    loadChildren: () =>
      import('./terms-avb/terms-avb.module').then((m) => m.TermsAVBModule),
  },
  {
    path: 'dental/granulat',
    loadChildren: () =>
      import('./silicone/pellets/pellets.module').then((m) => m.PelletsModule),
  },
  {
    path: 'dental/preise',
    loadChildren: () =>
      import('./silicone/remains/remains.module').then((m) => m.RemainsModule),
  },
  {
    path: 'dental',
    loadChildren: () =>
      import('./silicone/silicone.module').then((m) => m.SiliconeModule),
  },
  {
    path: 'sonderlegierung',
    loadChildren: () =>
      import('./alloys/alloys.module').then((m) => m.AlloysModule),
  },
  {
    path: 'werkzeugschrott',
    loadChildren: () =>
      import('./industrial/industrial.module').then((m) => m.IndustrialModule),
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: '404',
    loadChildren: () =>
      import('./error404/error404.module').then((m) => m.Error404Module),
  },
  {
    path: '**',
    loadChildren: () =>
      import('./error404/error404.module').then((m) => m.Error404Module),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
