<mat-sidenav-container>
  <mat-sidenav-content>
    <div class="page-wrapper">
      <vmm-navigation></vmm-navigation>
      <router-outlet></router-outlet>
      <div class="spacer"></div>
      <footer>
        <nav>
          <a routerLink="/kontakt">Kontakt</a>
          <a routerLink="/datenschutz">Datenschutz</a>
          <a routerLink="/agb">AGB</a>
          <a routerLink="/avb">AVB</a>
        </nav>
      </footer>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
